<template>
	<div>
	<ContentHeader title="Data Budgeting Keuangan"  />
     <div class="content-header">
		<div class="container-fluid">
			<div class="row justify-content-center">
	            <div class="col">
	            	<div class="card">
	            		<div class="card-header row d-flex align-items-center">
	            			<h3 class="card-title col-6">Data Budgeting Keuangan</h3>
	            			<div class="col-6 text-right">
	            				<!-- <button class="btn btn-sm btn-info" data-toggle="modal" data-target="#exampleModal">Tambah</button>	 -->
	            			</div>
	            		</div>
	            		<div class="card-body">
            				<div class="row">
            					<div class="col-lg-2"></div>
            					<div class="col-lg-8">
            						<form @submit.prevent="prosesBudgetKeuangan" class="mb-4">
		            					<div class="form-group">
					                  <label>Nama Anggaran</label>
					                  <multiselect 
											          v-model="akun_keuangan_select_value"
											          deselect-label="Can't remove this value"
											          track-by="nama_lengkap"
											          label="nama_lengkap"
											          placeholder=""
											          :custom-label="customSelect"
											          :options="akun_keuangan"
											          :allow-empty="false"
											          :loading="memuat_akun_keuangan"
											          @update:model-value="akun_keuangan_select_change"
											        >
											      </multiselect>
					                </div>
					                <div class="form-group">
					                  <label for="budget">Budget</label>
					                  <input type="text" class="form-control" id="budget" name="budget" autocomplete="off" v-model="budgeting_keuangan.budget">
					                </div>
					                <button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
														<div>Submit</div>	
														<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
														  <span class="sr-only">Loading...</span>
														</div>
													</button>
		            				</form>
            					</div>
            					<div class="col-lg-2"></div>
            				</div>
            				<div class="row align-items-center mt-4">
	            				<div class="col-lg-2">
	            					<span>Jumlah Baris</span>
	            					<select class="custom-select form-control" v-model="page" @change="changePage">
		            					<option value="10">10</option>
		            					<option value="15">15</option>
		            					<option value="20">20</option>
		            					<option value="25">25</option>
		            					<option value="30">30</option>
		            					<option value="40">40</option>
		            					<option value="45">45</option>
		            					<option value="50">50</option>
		            				</select>
	            				</div>
	            				<div class="col-lg-2">
	            					<span>Periode Awal</span>
	            					<input type="date" class="form-control" v-model="periode_awal">
	            				</div>
	            				<div class="col-lg-2">
	            					<span>Periode Akhir</span>
	            					<input type="date" class="form-control" v-model="periode_akhir">
	            				</div>
	            				<div class="col-lg-1 pt-4">
	            					<button class="btn btn-primary" @click="getDataResultByPeriode">Filter</button>
	            				</div>				
	            				<div class="col-lg-5"></div>
	            			</div>
	            			<table class="table table-bordered table-hover mt-4">
							  <thead>
							    <tr>
							      <th scope="col" class="text-sm">NO</th>
							      <th scope="col" class="text-sm">KODE AKUN</th>
							      <th scope="col" class="text-sm">NAMA AKUN</th>
							      <th scope="col" class="text-sm">BUDGET</th>
							      <th scope="col" class="text-sm">REALISASI</th>
							      <th scope="col" class="text-sm">SISA</th>
							      <th scope="col" class="text-sm">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in data_result" :key="row.id">
							    	<td>{{ index + 1 }}</td>
							    	<td>{{ row.akun_keuangan.kode }}</td>
							    	<td>{{ row.akun_keuangan.nama }}</td>
							    	<td>{{ format_nominal(row.budget) }}</td>
							    	<td>{{ format_nominal(row.realisasi) }}</td>
							    	<td>{{ format_nominal(row.sisa) }}</td>
							    	<td><button @click="konfirmasiHapus(row.id)" class="btn btn-sm btn-danger">Hapus</button></td>
							    </tr>
							  </tbody>
							</table>
							<p class="text-center mt-4" v-if="memuat_data">Memuat Data...</p>
							<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
	            		</div>
	            		<div class="card-footer">
							<nav aria-label="...">
							  <ul class="pagination">
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
							      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
							    </li>
							    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
							    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
							      <a class="page-link" href="#">Next</a>
							    </li>
							  </ul>
							</nav>
	            		</div>
	            	</div>
	            </div>
	        </div>
		</div>
	</div>

		<!-- Modal -->
		<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg" role="document">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        ...
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
		        <button type="button" class="btn btn-primary">Save changes</button>
		      </div>
		    </div>
		  </div>
		</div>

		<LoadingScreen v-if="loading_screen" />

    </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import { ref, onMounted, computed, reactive } from 'vue'
import store from '@/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import format_nominal from '@/format_nominal.js'
import LoadingScreen from '@/components/LoadingScreen.vue'
import Multiselect from '@suadelabs/vue3-multiselect'

export default{
	components: {
		ContentHeader,
		LoadingScreen,
		Multiselect
	},
    setup(){
    	const user = computed(() => store.getters['auth/user'])
		const data_result = ref([])
		const memuat_data = ref(true)
		const cek_data = ref(false)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])
		const akun_keuangan = ref([])
		const budgeting_keuangan = reactive({
			'cabang_id' : user.value.cabang_id,
			'akun_keuangan_id' : '',
			'budget' : '',
			'realisasi' : '',
			'sisa' : ''
		})
		const loading = ref(false)
		const loading_screen = ref(false)
		const memuat_akun_keuangan = ref(true)
		const periode_awal = ref('')
		const periode_akhir = ref('')

		const getAkunKeuangan = async () => {
			let { data } = await axios.get(`api/akun_keuangan/getAll`)

			if (data != 'kosong') {
				akun_keuangan.value = data
				memuat_akun_keuangan.value = false
			} else {
				akun_keuangan.value = []
				memuat_akun_keuangan.value = false
			}
		}

		const akun_keuangan_select_value = ref('')
        const customSelect = ({ kode, nama }) => {
          return `${kode} - ${nama}`
        }
        const akun_keuangan_select_change = () => {
            budgeting_keuangan.akun_keuangan_id = akun_keuangan_select_value.value.id
        }

		const getDataResult = async () => {
			data_result.value = []
	    	memuat_data.value = true
	    	cek_data.value = false
	    	loading_screen.value = true

			let { data } = await axios.get(`api/budgeting_keuangan/get/${user.value.cabang_id}/${page.value}`)

			if (data != 'kosong') {
				memuat_data.value = false
				cek_data.value = false
				data_result.value = data.data
				links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
    			loading_screen.value = false
			} else {
				cek_data.value = true
				memuat_data.value = false
				data_result.value = []
    			loading_screen.value = false
			}

			console.log(data_result.value)
		}

		const getDataResultByPeriode = async () => {
			if (periode_awal.value != '' && periode_akhir.value != '') {
				data_result.value = []
		    	memuat_data.value = true
		    	cek_data.value = false
		    	loading_screen.value = true

				let { data } = await axios.get(`api/budgeting_keuangan/getByPeriode/${user.value.cabang_id}/${page.value}/${periode_awal.value}/${periode_akhir.value}`)

				if (data != 'kosong') {
					memuat_data.value = false
					cek_data.value = false
					data_result.value = data.data
					links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    			loading_screen.value = false
				} else {
					cek_data.value = true
					memuat_data.value = false
					data_result.value = []
	    			loading_screen.value = false
				}	
			}
			
		}

		const changePage = () => {
			if (periode_awal.value != '' && periode_akhir.value != '') {
				getDataResultByPeriode()
			} else {
				getDataResult()
			}
		}

		const paginateData = async (url) => {

    		if (url != null) {
    			data_result.value = []
	    		memuat_data.value = true

	    		let link_url = new URL(url)
	    		link_url = `${link_url.pathname}${link_url.search}`
	    		
	    		let { data } = await axios.get(`${link_url}`)
	    		
	    		if (data != 'kosong') {
	    			memuat_data.value = false
	    			data_result.value = data.data	
	    			links.value = data.links
	    			links.value.splice(0, 1)
	    			links.value.splice(links.value.length-1, 1)
	    			pagination.value = data
	    		}
    		}
    	}

    	const prosesBudgetKeuangan = () => {
    		loading.value = true

    		budgeting_keuangan.realisasi = 0
    		budgeting_keuangan.sisa = budgeting_keuangan.budget
			axios.post('api/budgeting_keuangan/create', budgeting_keuangan)
			.then((response) => {
				if (response.data.message == 'sudah ada') {
					Swal.fire({
					  title: 'Gagal!',
					  text: 'Data Sudah Ada',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})	
					loading.value = false
					budgeting_keuangan.akun_keuangan_id = ''
					budgeting_keuangan.budget = ''
					akun_keuangan_select_value.value = ''
				} else {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil Menanbahkan Data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})	
					loading.value = false
					budgeting_keuangan.akun_keuangan_id = ''
					budgeting_keuangan.budget = ''
					getDataResult()
					console.log(response)	
				}
				
			})
			.catch((error) => {
				Swal.fire({
				  title: 'Gagal!',
				  text: 'Gagal Menanbahkan Data',
				  icon: 'error',
				  confirmButtonText: 'Ok'
				})	
				loading.value = false
				console.log(error)
			})
    	}

    	const konfirmasiHapus = (id) => {
	        Swal.fire({
	          title: 'Hapus Data?',
	          icon: 'warning',
	          showCancelButton: true,
	          confirmButtonText: `Ya`,
	          cancelButtonText: `Batal`,
	        }).then((result) => {
	          /* Read more about isConfirmed, isDenied below */
	          if (result.isConfirmed) {
	            hapusDataTindakan(id)
	          } 
	        })
	    }

	    const hapusDataTindakan = (id) => {
    		axios.delete(`api/budgeting_keuangan/destroy/${id}`)
    		.then((response) => {
    			Swal.fire({
		            title: 'Berhasil!',
		            text: 'Berhasil Menghapus Data',
		            icon: 'success',
		            showConfirmButton: false,
		            timer: 1500
		          })
    			console.log(response.data)
	    		getDataResult()
    		})
    		.catch((error) => {
    			Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal menghapus data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})
    			console.log(error)
    		})

    	}


		onMounted(() => {
			getDataResult()
			getAkunKeuangan()
		})

		return { data_result, memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, akun_keuangan, prosesBudgetKeuangan, budgeting_keuangan, loading, konfirmasiHapus, format_nominal, periode_awal, periode_akhir, loading_screen, getDataResultByPeriode, changePage, akun_keuangan_select_value, customSelect, akun_keuangan_select_change }
    }
}
</script>

<style>
	
</style>